import { Divider, Image, Typography } from 'antd';
import Link from 'next/link';
import {BankOutlined, MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import {useAppSelector} from "@/hooks";
import {selectPlatform} from "@/store/platform";

const serverLinks = [
  {
    href: '/product?tag=xxx',
    label: '社区',
  },
  {
    href: '/product?tag=xxx',
    label: '电商',
  },
  {
    href: '/product?tag=xxx',
    label: '文旅',
  },
  {
    href: '/product?tag=xxx',
    label: '直播',
  },
  {
    href: '/product?tag=xxx',
    label: '连锁',
  },
  {
    href: '/product?tag=xxx',
    label: '出海',
  },
  {
    href: '/product?tag=xxx',
    label: '微服务',
  },
  {
    href: '/product?tag=xxx',
    label: '开放API',
  },
];
const platformInfo = {
  wechatCustomerServiceUrl:
    'https://work.weixin.qq.com/kfid/kfc0cc36ae7e9b31178',
};

export default function BottomSection() {
  const platform = useAppSelector(selectPlatform)
  return (
    <section>
      <div className='flex-row px-10 justify-sb mx-auto max-w-5xl'>
        <div>
          <div className='flex-row justify-start'>
            <Image
              alt='logo'
              width={122}
              preview={false}
              style={{ height: '30px' }}
              src='/images/logo-long-secondary.svg'
            />
          </div>
          <div className='px-2 mt-2'>
            <Typography.Text type='secondary'>
              {platform.title} · {platform.slogan}
            </Typography.Text>
          </div>
          <div className='sm-hide mt-3 flex-row items-center  wrap px-2'>
            {serverLinks.map((link, index) => (
              <div key={index}>
                {index > 0 && <Divider type='vertical' />}
                <Link href={link.href}>
                  <Typography.Text type='secondary'>
                    {link.label}
                  </Typography.Text>
                </Link>
              </div>
            ))}
          </div>
          <div className='mt-3 flex-row items-center  wrap px-2'>
            <Link href={platform.email} className={"mr-4"}>
              <Typography.Text type='secondary'>
                <MailOutlined /> {platform.email}
              </Typography.Text>
            </Link>
            <Link href={platform.wechatUrl} className={"mr-4"}>
              <Typography.Text type='secondary'>
                <WhatsAppOutlined /> 商户合作
              </Typography.Text>
            </Link>
              <Typography.Text type='secondary' className={"mr-4"}>
                <BankOutlined /> {platform.businessCode}
              </Typography.Text>
          </div>
        </div>
        <div className={'flex-row justify-end items-center gap-x-6'}>
          <div>
            <Image
              className='rounded-sm'
              style={{ height: '80px', width: '80px' }}
              src={platform.WNPQrcode || '/images/qrcode_for_gh.jpg'}
              alt='微信公众号'
            />
            <Typography.Paragraph
              className='py-1 text-center'
              type='secondary'
              style={{ fontSize: '12px' }}
            >
              扫码关注我们
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </section>
  );
}
