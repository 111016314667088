import { ArrowDownOutlined } from '@ant-design/icons';
import { Divider, Typography } from 'antd';
import * as React from 'react';

import { useAppSelector } from '@/hooks';

import BottomSection from '@/components/BottomSection';
import Hero from '@/components/Hero';
import PageLayout from '@/components/Layout/PageLayout';
import MetaBackground from '@/components/MetaBackground';
import Seo from '@/components/Seo';

import { selectTheme } from '@/store/theme';

export default function HomePage() {
  const theme = useAppSelector(selectTheme);
  return (
    <PageLayout headerOpacityOnScroll={true} sticky={true}>
      <Seo
        templateTitle='境际之光'
        title='广州境际之光科技有限公司'
        description='广州境际之光科技有限公司'
      />
      <div
        className='relative z-10 flex-col items-center justify-center'
        style={{ height: 'calc(100vh - 64px)' }}
      >
        {' '}
        <div className="top-0 w-full">
          {theme === 'dark' && <MetaBackground />}
        </div>
        <div style={{ marginTop: '-100px' }}>
          <Hero />
        </div>
      </div>
      <section className="relative flex-col justify-center items-center">
        <Typography.Text className="absolute z-10" style={{ top: '-40px' }}>
          探索 <ArrowDownOutlined />
        </Typography.Text>
      </section>
      <Divider />
      <BottomSection />
    </PageLayout>
  );
}
