import { ArrowRightOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import {Button, Carousel, Typography} from 'antd';
import Link from 'next/link';
import * as React from 'react';

import CompanyPrimary from '@/components/CompanyPrimary';

const { Title, Paragraph, Text } = Typography;
export default function Hero() {
  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  return (
    <div className='relative overflow-hidden'>
      <div className='py-20 px-6 mx-auto max-w-2xl'>
        <div className='flex-col justify-center items-center  text-center'>
          <CompanyPrimary />
          <Paragraph className='mx-auto mt-6'>
            商业互联网技术支持 ｜ 本地生活社区数字化 ｜ 低碳可再生技术支持
          </Paragraph>
          <div className='mt-10 flex-row items-center justify-center gap-x-6'>
            <Link
              href='https://work.weixin.qq.com/kfid/kfc0cc36ae7e9b31178'
              className='text-sm font-medium'
            >
              <Button
                type='primary'
                size='large'
                icon={<CustomerServiceOutlined />}
              >
                在线联系
              </Button>
            </Link>
            <Link
              href='/news'
              className='text-sm font-medium leading-6 text-white'
            >
              <Button size='large'>
                企业动态 <ArrowRightOutlined />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
