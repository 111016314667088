import {
  GithubOutlined,
  TwitterOutlined,
  WechatFilled,
} from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';
import Image from 'next/image';
import Link from 'next/link';

import IconFont from '@/components/IconFont';
import { motion } from 'framer-motion';

const person = {
  title: '广州境际之光科技有限公司',
  role: '境际之光 · 探索无限可能',
  imageUrl: '/images/avatar.png',
  twitterUrl: 'https://twitter.com/yunhouhuang',
  wechatUrl: 'https://work.weixin.qq.com/kfid/kfc0cc36ae7e9b31178',
};
const { Title, Paragraph, Text } = Typography;

export default function CompanyPrimary() {
  return (
    <div className='mx-auto'>
      <div key={person.title} className='rounded-2xl py-10 px-8 no-dto'>
        <motion.div
          className='box'
          whileHover={{ scale: 1.12 }}
          whileTap={{ scale: 0.85 }}
          transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        >
          <Image
            className='mx-auto rounded-full'
            priority={true}
            width={222}
            height={222}
            src={person.imageUrl}
            alt=''
          />
        </motion.div>
        <Title level={2} className='pt-10'>
          {person.title}
        </Title>
        <Paragraph className='text-sm mb-10 text-gray-400'>
          {person.role}
        </Paragraph>
        <Space size={[0, 8]} wrap>
          <Link href={person.wechatUrl} target='_blank'>
            <Tag icon={<WechatFilled />} color='#07C160'>
              Wechat
            </Tag>
          </Link>
          <Link href={person.twitterUrl} target='_blank'>
            <Tag icon={<TwitterOutlined />} color='#479aec'>
              Twitter
            </Tag>
          </Link>
        </Space>
      </div>
    </div>
  );
}
